.users__search-wrapper .ant-input-affix-wrapper, .users__search-wrapper .ant-input {
    background: white !important;
}

.users__search-wrapper .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9 !important;
}

.ant-drawer-content-wrapper {
    width: 768px !important;
}

@media screen and (max-width: 768px) {
    .users__header {
        flex-direction: column;
    }

    .users__search-wrapper {
        flex: 1;
    }

    .ant-drawer-content-wrapper {
        width: 378px !important;
    }
}
