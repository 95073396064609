.training__content {
    text-align: justify;
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
}

.training__content > .ant-card-body {
    min-width: 100%;
}

.training__content span, p, ul, li, h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
}

.training__content p {
    color: #333333;
    margin: 0.5rem 0;
}

.training__content ul {
    list-style-type: circle;
}

.training__content li {
    margin-left: 2rem;
    color: #333333;
    font-size: 1rem;
}
