.software__card {
    text-align: justify;
    min-width: 100%;
    max-width: 100%;
    display: block;
}

.software__card p {
    color: #333333;
}

@media screen and (max-width: 768px) {
    .software__card {
        width: calc(100vw - 2rem) !important;
    }
}
