/*.ant-menu  {*/
/*    max-width: 40px!important;*/
/*}*/

.ant-menu-inline-collapsed {
    width: 40px !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
    padding-inline: 7px !important;
    width: 30px;
}

.ant-menu-inline-collapsed > .ant-menu-item-selected, .ant-menu > .ant-menu-item-selected {
    background: rgba(0, 0, 0, 0.10);
}

.admin-nav .ant-menu-sub {
    background: none !important;
}

.admin-nav .ant-menu-vertical {
    background: #FBFBFB !important;
}

.desktop {
    display: block !important;
}

@media (max-width: 768px) {
    .desktop {
        display: none !important;
    }
}
