.user-import-modal__info {
    flex: 1;
    align-self: stretch;
}

.user-import-modal__file-import {
    margin-top: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

}
