.ant-picker-ok > .ant-btn {
    background: #49000c;
    border-radius: 0 !important;
    padding: 4px 16px !important;
    height: auto;
    color: white;
    font-weight: 500;
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
}

.ant-picker-ok > .ant-btn:hover {
    background: #ae0622 !important;
}
