.system__auth-mode__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.system {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
}

.system__default-apps > .ant-card-body, .system__auth-mode > .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
}

.system__default-apps, .system__ldap-configuration, .system__auth-mode, .system__site-settings {
    background: white;
}

.system__headings {
    line-height: 1;
    margin: 0;
}

.date-picker .ant-picker-ok .ant-btn {
    border-radius: 0;
    background: #49000c;
}

.date-picker .ant-picker-ok .ant-btn:hover {
    border-radius: 0;
    background: #ae0622;
}

.date-picker .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #eee!important;
}
