.training-redaction {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.training-redaction__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    min-width: 100%;
}

.loader {
    width: 100%;
}

.training-redaction__card p {
    font-size: 1rem !important;
    color: #222;
}

@media (max-width: 768px) {
    .training-redaction__content {
        flex-direction: column;
        gap: 0.5rem;
        width: 100% !important;
    }
}
